import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from './components/Layout/Loader';
import { observer } from 'mobx-react';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import initFontAwesome from './utils/initFontAwesome';
import Layout from './layout/layout';
import ThemeConfig from './theme';
import routes from './routes';
import { Grow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import loadingStore from './store/loading';

initFontAwesome();

const App = () => {
  const location = useLocation();
  const backgroundLocation = location.state?.backgroundLocation;
  const { isloading } = loadingStore;


  return (
    <>
      <SnackbarProvider
        maxSnack={4}
        action={(snackbarId) => (
          <CloseIcon
            variant="outlined"
            style={{ color: 'white' }}
            onClick={() => closeSnackbar(snackbarId)}
          />
        )}
        autoHideDuration={3000}
        TransitionComponent={Grow}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        {(isloading || isloading) && <Loader />}
        {!isloading && (
          <ThemeConfig>
            <Layout>
              <Routes location={backgroundLocation || location}>
                {routes.map((r, i) => (
                  <Route key={i} path={r.path}
                  element={(<r.element />)}
                  />
                ))}
              </Routes>
            </Layout>
          </ThemeConfig>
        )}
      </SnackbarProvider>
    </>
  );
};

export default observer(App);
