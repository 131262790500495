// api.js
import { axios } from '../utils/axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchChannels = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/wrapper/channels`);
    return response.data;
  } catch (error) {
    console.error('Error fetching channels:', error);
    throw error;
  }
}

export const connectChannel = async (params) => {
  try {
    const response = await axios.post(`${BASE_URL}/wrapper/channels/connect`, params);
    return response.data;
  } catch (error) {
    console.error('Error connecting to channel:', error);
    throw error;
  }
}

export const fetchChannelTypes = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/wrapper/channels/types`);
    return response.data;
  } catch (error) {
    console.error('Error fetching channel types:', error);
    throw error;
  }
}