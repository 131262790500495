import { useState } from 'react';
import './marvinlayout.css';

export default function MarvinLayout ({ children }) {
  const [mainContentStyle] = useState('md:ml-68 main-content-transition');

  return (
    <>
        <div className={`relative h-full bg-gray-light min-h-screen ${mainContentStyle}`}>
          <div className='w-full'>
            {/* <div className='flex flex-wrap'> */}
              <main>{children}</main>
            {/* </div> */}
          </div>
        </div>
    </>
  );
}
