import axios from 'axios';

// Store access token from URL to local storage
const storeAccessTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('access_token');
  if (accessToken) {
    localStorage.setItem('access_token', accessToken);
  }
  const marvinServiceSignature =  urlParams.get('marvin_service_signature');
  if (marvinServiceSignature) {
    localStorage.setItem('marvin_service_signature', marvinServiceSignature);
  }
  const redirectUrl =  urlParams.get('redirectUrl');
  if (redirectUrl) {
    localStorage.setItem('redirectUrl', redirectUrl);
  }
};

// Add a request interceptor to include the access token in all API requests
const addAccessTokenToRequest = (config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const marvinServiceSignature = localStorage.getItem('marvin_service_signature');
  if (marvinServiceSignature) {
    config.headers['Marvin-Service-Signature'] = marvinServiceSignature;
  }
  return config;
};

// Setup Axios interceptor
axios.interceptors.request.use(addAccessTokenToRequest, (error) => Promise.reject(error));

// Export the storeAccessTokenFromUrl function and axios instance
export { storeAccessTokenFromUrl, axios };