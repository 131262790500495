import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import StarIcon from "@mui/icons-material/Star";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SocialIcon from "@mui/icons-material/Web";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { fetchChannelTypes, connectChannel } from '../../api/wrapper';
import Alert from '@mui/material/Alert';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Wizard() {
  const [channelTypes, setChannelTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState("success");
  const [channelId, setChannelId] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    if (success) {
      setDialogMessage('Channel connected successfully');
      setDialogType("success");
      setOpen(true);
      setChannelId(urlParams.get('channelId'));
    }

    const error = urlParams.get('error');
    if (error) {
      setDialogMessage('Error connecting to channel');
      setDialogType("error");
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    const getChannelTypes = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        setDialogMessage("No access token found. Please log in to continue.");
        setDialogType("error");
        setOpen(true);
        setLoading(false);
        return;
      }

      const marvinServiceSignature = localStorage.getItem('marvin_service_signature');
      if (!marvinServiceSignature) {
        setDialogMessage("No marvin service signature found. Please log in to continue.");  
        setDialogType("error");         
        setOpen(true);
        setLoading(false);
        return;
      }

      try {
        const data = await fetchChannelTypes();
        setChannelTypes(data);
      } catch (error) {
        console.error('Error fetching channels:', error);
      } finally {
        setLoading(false);
      }
    };
    getChannelTypes();
  }, []);

  const handleClose = () => {
    setOpen(false);
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      const url = new URL(redirectUrl);
      url.searchParams.set('success', dialogType === 'success' ? 'true' : 'false');
      if (channelId) {
        url.searchParams.set('channelId', channelId);
      }
      localStorage.removeItem('redirectUrl');
      window.location.href = url.toString();
    }else{  
      window.close();
    }
  };

  const onClickConnect = async (params) => {
    try {
      const response = await connectChannel(params);
      if (response.redirectUrl) {
        window.location.href = response.redirectUrl;
      } else {
        setDialogMessage('Channel connected successfully');
        setDialogType("success");
        setOpen(true);
      }
    } catch (error) {
      setDialogMessage('Error connecting to channel');
      setDialogType("error");
      setOpen(true);
      console.error('Error connecting to channel:', error);
    }
  };

  return (
    <div className="w-full min-h-full flex overflow-auto relative">
      <div className="w-full min-h-screen relative">
        <img
          className="absolute w-full h-full object-cover"
          alt="bg"
          src={process.env.PUBLIC_URL + "/static/images/signup-bg.svg"}
        />
        <div className="relative h-full">
          <div className="content-center items-center flex justify-center h-full p-5">
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              rowSpacing={{ xs: 5, sm: 5, md: 12 }}
            >
              <Grid item xs={12} >
                <p className="absolute z-10 text-lgfont-bold left-5 top-5">
                  <img
                    className="h-14 object-contain"
                    src={
                      process.env.PUBLIC_URL + "/static/icons/Marvinlogobig.svg"
                    }
                    alt="logo"
                  />
                </p>
              </Grid>
              <Grid item md={0} xs={0}></Grid>
              <Grid
                item
                md={"auto"}
                xs={12}
                className="align-middle items-center justify-center content-center"
                style={{
                  width: "50%",
                  margin: 'auto'
                }}
              >
                <Item>
                  <div className='flex flex-col px-20 py-10 bg-white rounded-lg flex-grow text-center items-center'>
                    <h2 className="text-2xl font-bold mb-4">Connect Your Channels</h2>
                    <p className="text-gray-600 mb-8 w-auto">
                      Choose from a variety of platforms like Instagram, Facebook, LinkedIn, and more. Seamlessly integrate your channels to streamline your workflow and manage your content in one place.
                    </p>
                    {loading ? (
                      <Box textAlign="center" mt={3}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      channelTypes.map((channel) => {
                        const buttonColor = getButtonColor(channel.channelName);
                        return (
                          <Box textAlign="center" mt={3} key={channel.id}>
                            <Button
                              variant="contained"
                              startIcon={getChannelIcon(channel.channelName)}
                              sx={{
                                backgroundColor: buttonColor,
                                color: "#fff",
                                borderRadius: "30px",
                                width: "100%",
                                px: 4,
                                py: 1.5,
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "#333",
                                  transform: "scale(1.05)",
                                },
                              }}
                              onClick={() => onClickConnect({
                                channelTypeId: channel.id,
                                name: channel.channelName,
                                requireUniqueName : true
                            })}
                            >
                              Connect with {channel.channelName}
                            </Button>
                          </Box>
                        );
                      })
                    )}
                  </div>
                </Item>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Alert severity={dialogType === "success" ? 'success' : 'error'}>{dialogMessage}</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    
    </div>
  );

  function getButtonColor(channelName) {
    switch (channelName) {
      case "Facebook Page":
        return "#3b5998";
      case "Formerly Twitter":
        return "#1DA1F2";
      case "Instagram Business":
      case "Instagram":  
        return "#E1306C";
      case "Linkedin":
      case "Linkedin Business":
        return "#0077B5";
      case "Premium":
        return "#FFD700";
      case "Telegram":
        return "#0088cc";
      case "YouTube Shorts":
        return "#FF0000";
      default:
        return "#f50057";
    }
  }

  function getChannelIcon(channelName) {
    switch (channelName) {
      case "Facebook Page":
        return <FacebookIcon />;
      case "Formerly Twitter":
        return <TwitterIcon />;
      case "Instagram Business":
      case "Instagram":  
        return <InstagramIcon />;
      case "Linkedin":
      case "Linkedin Business":
        return <LinkedInIcon />;
      case "Premium":
        return <StarIcon />;
      case "Telegram":
        return <TelegramIcon />;
      case "YouTube Shorts":
        return <YouTubeIcon />;
      default:
        return <SocialIcon />;
    }
  }
}
