import React from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Layout/Loader';
import MarvinLayout from '../components/Layout/MarvinLayout';

const Layout = ({ children }) => {
  const location = useLocation();
  const [permissionError] = React.useState(false);
  const [permissionChecked] = React.useState(true);

  return (
    <>
      {!permissionError && permissionChecked
        ? (
        <>
          {location.pathname.includes('/content/')
            ? (
            <>{children} </>
              )
            : (
            <MarvinLayout>{children}</MarvinLayout>
              )}
        </>
          )
        : (<Loader />)}
    </>
  );
};

export default Layout;
