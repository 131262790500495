// eslint-disable-next-line no-unused-vars
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/style.css';
import './assets/css/QuillSnowflake.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './utils/history';
import { getConfig } from './config';
import { BrowserRouter } from 'react-router-dom';
import { isSafari, isFirefox } from 'react-device-detect';
import { storeAccessTokenFromUrl } from './utils/axios';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const isBrave = async () => {
  if (navigator.brave) {
    try {
      return await navigator.brave.isBrave();
    } catch (error) {
      console.error('Error checking if Brave:', error);
      return false;
    }
  } else {
    return false;
  }
};

const useLocalStorage = !!(isSafari || isFirefox || isBrave);

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null)
  },
  useRefreshTokens: useLocalStorage,
  cacheLocation: useLocalStorage ? 'localstorage' : 'memory'
};

storeAccessTokenFromUrl();

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();