import Dashboard from '../pages/Dashboard';

const routes = [
  {
    path: '/',
    element: Dashboard,
    permissions: []
  },
  {
    path: '*',
    element: Dashboard,
    permissions: []
  }
];

export default routes;
